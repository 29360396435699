<template>
  <v-card class="mt-3">
    <div class="text-center" v-if="loading">
      <v-progress-linear color="primary" indeterminate></v-progress-linear>
    </div>
    <v-tabs v-if="!loading" v-model="tab" grow dark height="35" background-color="primary">
      <v-tab :disabled="this.hourObj.id ? tab !== 0 : false">
        <v-icon color="white">mdi-calendar-check</v-icon>
        <span class="ml-2 text-capitalize">{{ $t('message.timer.hourlogging') }}</span>
      </v-tab>
      <v-tab :disabled="this.hourObj.id ? tab !== 1 : false">
        <v-icon color="white">mdi-calendar-remove</v-icon>
        <span class="ml-2 text-capitalize">{{ $t('message.timer.nonBillable') }}</span>
      </v-tab>
    </v-tabs>
    <v-card-text v-if="!loading" class="pa-1 mt-1">
      <Slider :timeline="hourlogSingleDayRecords.timeLine"></Slider>
    </v-card-text>
    <v-tabs-items v-if="!loading" v-model="tab">
      <v-tab-item>
        <NormalHours v-if="tab === 0" :hourObj="hourObj" :listItems="normalHoursListItems" :isWeekLocked="weekLockStatus" :backLink="backLink" /><!-- :allHourRecords="getAllNormalHourLogs" :sliderOptions="sliderOptions" -->
      </v-tab-item>
      <v-tab-item>
        <AbsenceHours v-if="tab === 1" :hourObj="hourObj" :listItems="absenceHoursListItems" :isWeekLocked="weekLockStatus" :backLink="backLink" /><!-- :allHourRecords="getAllNonbillableHourLogs" :sliderOptions="sliderOptions"-->
      </v-tab-item>
    </v-tabs-items>
    <v-card  flat class="mt-3" v-if="(tab === 0 ? getAllNormalHourLogs.length > 0 : getAllNonbillableHourLogs.length > 0) && !loading">
      <v-card-title class="pa-1 py-0" v-if="isshowTitle">{{ $t('message.calendar.otherHourEntries')}}</v-card-title>
      <v-card-text class="px-1 py-0">
        <template v-for="(hourRecord, index) in tab === 0 ? getAllNormalHourLogs : getAllNonbillableHourLogs">
          <v-list v-if="hourObj.id !== hourRecord.id" :key="`${hourRecord.id}_${index}`" class="pa-0 py-1">
            <v-list-item class="pa-0 custom_box-shadow" @click="getSingleRecordHandler(hourRecord.id)">
              <v-list-item-avatar color="primary my-0" tile size="65" class="mr-2" style="border-radius: 4px;">
                <span class="text-center mb-0 white--text subtitle-2">{{getStartEndTimes(hourRecord)}}</span>
              </v-list-item-avatar>
              <v-list-item-content class="pa-1" style="background:#fff;">
                <v-list-item-title class="h6 mb-1 font-weight-medium">{{ getProjectName(hourRecord.project_id) }}</v-list-item-title>
                <v-list-item-title class="subtitle-2">{{ getWorktypeName(hourRecord.work_id) }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="my-0">
                <v-list-item-action-text>
                  <v-chip class="mr-1 mt-1" color="#2196f3" small dark>{{ getHours(hourRecord) }}</v-chip>
                </v-list-item-action-text>
                <v-btn icon v-if="canDelete(hourRecord.state)" @click.stop="deleteHourEntry(hourRecord.id)">
                  <v-icon color="red lighten-1">mdi-delete-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import CONSTANTS from '@/assets/json/constants.json'
import moment from 'moment'
import goTo from 'vuetify/lib/services/goto'
export default {
  data () {
    return {
      tab: 0,
      hourObj: {
        id: this.$route.params.hour_id,
        date: moment().format('DD.MM.YYYY'),
        start_date: '',
        end_date: '',
        shift_id: '',
        amount: process.env.VUE_APP_STANDARD_HOURS_PER_DAY // for task 10533
      },
      listOfShifts: [],
      pauseSettings: [],
      hourlogSingleDayRecords: {
        normalHours: [],
        nonBillableHours: [],
        timeLine: {},
        totalNormalHours: 0,
        totalNonBillableHours: 0
      },
      sliderOptions: {},
      backLink: '',
      loading: false,
      prevRoute: null
    }
  },
  async beforeRouteEnter (to, from, next) {
    await next(vm => {
      vm.prevRoute = from
    })
  },
  created () {
    this.loading = true
    this.$store.dispatch('getWorktypes')
    this.$store.dispatch('getGeneralSettings').then(() => {
      if (this.$store.state.common.isCalendarFormExcluded) this.$router.push('/')
      else {
        const pausesettingurl = this.generalSettings.pause_setting_type === 1 ? 'pausesettingbytimes' : 'pausesettingbyvalues'
        if (this.generalSettings.use_shift) {
          this.getShifts()
            .then(() => {
              this.getAllPauseSettings(pausesettingurl)
                .then(() => {
                  this.getHourLogs()
                })
            })
        } else {
          this.getAllPauseSettings(pausesettingurl)
            .then(() => {
              this.getHourLogs()
            })
        }
      }
    })
  },
  computed: {
    ...mapGetters(['generalSettings', 'getWorktypes', 'getuserDetails', 'getCurrentProjects']),
    normalHoursListItems () {
      return {
        workTypes: this.getWorktypes,
        listOfShifts: this.listOfShifts,
        pauseSettings: this.pauseSettings,
        listOfProjects: this.getCurrentProjects
      }
    },
    absenceHoursListItems () {
      return {
        workTypes: this.getWorktypes,
        listOfShifts: this.listOfShifts
      }
    },
    /* sliderOptions () {
      const timeLineObj = this.getTimeLineValues(this.hourlogSingleDayRecords.timeLine)
      return timeLineObj
    }, */
    getAllNormalHourLogs () {
      return this.hourlogSingleDayRecords.normalHours
    },
    getAllNonbillableHourLogs () {
      return this.hourlogSingleDayRecords.nonBillableHours
    },
    weekLockStatus () {
      return this.$store.state.common.isMonthLocked
    },
    isshowTitle () {
      const hourRecords = this.tab === 0 ? this.getAllNormalHourLogs : this.getAllNonbillableHourLogs
      return this.hourObj.id ? hourRecords.length > 1 : hourRecords.length > 0
    }
  },
  mounted () {
    if (this.$route.query.project_id) {
      this.backLink = `/projects/${this.$route.query.project_id}`
      this.hourObj.project_id = parseInt(this.$route.query.project_id)
    } else this.backLink = '/calendar'
    const text = 'message.common.deleteSuccess'
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === 'hours' || data === 'hours-others') {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        if (data === 'hours') this.$router.push('/calendar')
        else this.getHourLogs()
      }
    })

    /* if (this.generalSettings.use_shift) this.getShifts()
    if (this.generalSettings.pause_setting_type === 1) this.getAllPauseSettings('pausesettingbytimes')
    else this.getAllPauseSettings('pausesettingbyvalues') */
    // this.getHourLogs()
    this.$eventBus.$on('getactiveDateHourRecords', (date) => {
      this.hourObj.start_date = date
      this.hourObj.date = date
      this.getAllTheLogsForSingleDay(date)
    })
  },
  methods: {
    getHourLogs () {
      if (this.hourObj.id) {
        this.getSingleRecordHandler(this.hourObj.id)
      } else {
        this.getAllTheLogsForSingleDay(this.hourObj.date)
      }
    },
    checkWeekLock (date) {
      this.$store.dispatch('checkMonthLockedStatus', date).then((response) => { // true or false value
        if (response) this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.calendar.weekLock' })// notification show for month locked
      })
    },
    canDelete (state) {
      if (state === CONSTANTS.NOT_DELIVERED || state === CONSTANTS.NOT_APPROVED) {
        return true
      } else return false
    },
    setDefaultValues (data) {
      const { list, prop } = data
      const item = list.find(x => x.isdefault)
      if (item) {
        const model = Object.assign({ ...this.hourObj }, { [prop]: item.id })
        this.hourObj = model
      }
    },
    getSingleRecordHandler (id) {
      this.$api.getSingleRecordHandler(`hours/${id}`)
        .then((response) => {
          const hourLog = this.$formatter.cloneVariable(response.data)
          // const obj = this.listOfShifts.find(x => x.time_from === hourLog.starttime && x.time_till === hourLog.endtime)
          // hourLog.shift_id = obj ? obj.id : 0
          const start = moment(hourLog.start_date, 'DD.MM.YYYYTHH:mm:ss')
          this.getAllTheLogsForSingleDay(start.format('DD.MM.YYYY'))
          const end = moment(hourLog.end_date, 'DD.MM.YYYYTHH:mm:ss')
          hourLog.date = start.format('DD.MM.YYYY')
          hourLog.starttime = start.format('HH:mm')
          hourLog.endtime = end.format('HH:mm')
          switch (hourLog.type) {
            case CONSTANTS.WORKING:
              // if (this.generalSettings.use_shift) {
              //   const obj = this.listOfShifts.find(x => x.time_from === hourLog.starttime && x.time_till === hourLog.endtime)
              //   hourLog.shift_id = obj ? obj.id : 0
              // }
              hourLog.amount = hourLog.amount ? this.$formatter.replaceDotWithComma(hourLog.amount) : 0
              this.generalSettings.standard_start_time = end.format('HH:mm:ss')
              var milliseconds = this.$formatter.getMilliseconds(hourLog.break_hr)
              var duration = moment.duration(milliseconds)
              hourLog.break_hr = parseInt(duration.asMinutes())
              this.isBreakChanged = false
              this.tab = 0
              break
            case CONSTANTS.NON_WORKING:
              hourLog.amount = hourLog.amount ? this.$formatter.replaceDotWithComma(hourLog.amount) : 0
              hourLog.start_date = start.format('DD.MM.YYYY')
              hourLog.end_date = end.format('DD.MM.YYYY')
              this.tab = 1
              break
            default:
          }
          if (response.data) this.hourObj = this.$formatter.cloneVariable(hourLog)
          /* if other hour entry is selected, go to top */
          if (window.pageYOffset > 15) goTo(0)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getShifts () {
      return this.$api.execute('get', 'shiftsettings/get_active')
        .then((response) => {
          this.listOfShifts = this.$formatter.cloneVariable(response.data)
        })
    },
    getAllPauseSettings (url) {
      return this.$api.execute('get', url).then(response => {
        if (response.data) {
          this.pauseSettings = response.data
        }
      })
    },
    getTimeLineValues (timeline) {
      const timeLineObj = { min: 4, max: 18, value: [4, 4] }
      const start = isFinite(timeline.start) ? timeline.start : 0
      const end = isFinite(timeline.end) ? timeline.end : 0
      this.setStartTime(end)
      if (start !== '' || end !== '') {
        if (timeLineObj.max < start || timeLineObj.max < end) {
          timeLineObj.max = timeLineObj.max <= 24 ? end : end + 2
        }
        if (timeLineObj.min > start || timeLineObj.min > end) {
          timeLineObj.min = timeLineObj.min >= 0 ? start : start - 2
        }
        timeLineObj.value = [start, end]
      } else {
        timeLineObj.value = [4, 4]
        timeLineObj.max = 18
      }
      timeLineObj.sliderLabels = []
      for (var i = timeLineObj.min; i <= timeLineObj.max; i++) {
        timeLineObj.sliderLabels.push(i)
      }
      return timeLineObj
    },
    setStartTime (end) {
      if (!this.hourObj.id) {
        if (end) this.hourObj.starttime = end.toFixed(2).replace('.', ':')
        else {
          const reminder = 15 - (moment().minute() % 15)
          this.hourObj.starttime = moment().add(reminder, 'minutes').format('HH:mm')
        }
      }
    },
    getAllTheLogsForSingleDay (date) { // format as DD.MM.YYYY
      this.checkWeekLock(date) // check for the month lock
      const start = moment(date, 'DD.MM.YYYY').format('MM.DD.YYYY')
      const end = moment(date, 'DD.MM.YYYY').format('MM.DD.YYYY 23:59:59')
      const model = { start: start, end: end, users: [this.getuserDetails.id], showAllProjects: true }
      this.$api.execute('post', 'hours/filter', model).then((response) => {
        var hoursList = response.data
        let totalOfWork = 0
        let totalOfAbsense = 0
        var listStartTime = []; var listOfEndTime = []
        for (var i = 0; i < hoursList.length; i++) {
          if (hoursList[i].type === 'working') {
            totalOfWork += hoursList[i].amount - hoursList[i].break_hr
          }
          if (hoursList[i].type === 'non_working') {
            totalOfAbsense += hoursList[i].amount
          }
          if (!isNaN(hoursList[i].break_hr)) {
            totalOfAbsense += hoursList[i].break_hr
          }
          const startDate = moment(hoursList[i].start_date, 'DD.MM.YYYY[T]HH.mm.ss').format('DD.MM.YYYY')
          const endDate = moment(hoursList[i].end_date, 'DD.MM.YYYY[T]HH.mm.ss').format('DD.MM.YYYY')
          if (startDate === endDate) {
            listStartTime.push(moment(hoursList[i].start_date, 'DD.MM.YYYY[T]HH.mm.ss').format('HH.mm'))
            listOfEndTime.push(moment(hoursList[i].end_date, 'DD.MM.YYYY[T]HH.mm.ss').format('HH.mm'))
          }
          hoursList[i].startingTime = Math.min(...listStartTime)
          hoursList[i].endingTime = Math.max(...listOfEndTime)
        }
        const start = isFinite(Math.min(...listStartTime)) ? Math.min(...listStartTime) : ''
        const end = isFinite(Math.max(...listOfEndTime)) ? Math.max(...listOfEndTime) : ''
        this.hourlogSingleDayRecords.timeLine = { start, end }
        this.hourlogSingleDayRecords.totalNormalHours = totalOfWork
        this.hourlogSingleDayRecords.totalNonBillableHours = totalOfAbsense
        this.hourlogSingleDayRecords.normalHours = hoursList.filter(x => x.type === CONSTANTS.WORKING)
        this.hourlogSingleDayRecords.nonBillableHours = hoursList.filter(x => x.type === CONSTANTS.NON_WORKING)
        this.hourObj.total = `${this.$formatter.replaceDotWithComma(totalOfWork)} (${this.$formatter.replaceDotWithComma(totalOfAbsense)})t`
        // const lastUsedShift = localStorage.getItem(`lastShiftUsed_${this.$store.state.auth.userDetails.id}`)
        // if (lastUsedShift) this.hourObj.shift_id = Number(lastUsedShift)
        // else
      })
        .finally(() => {
          this.loading = false
        })
    },
    getProjectName (id) {
      const obj = this.getCurrentProjects.find(x => x.id === id)
      return obj ? obj.name : ''
    },
    getWorktypeName (id) {
      const obj = this.getWorktypes.find(x => x.id === id)
      const trans = this.$i18n.locale === 'en' ? 'en' : 'no'
      return obj ? obj[trans + '_name'] : ''
    },
    getStartEndTimes (hour) {
      return `${this.getTimeFromDate(hour.start_date)} - ${this.getTimeFromDate(hour.end_date)}`
    },
    getTimeFromDate (date) {
      return this.$formatter.formatDate(date, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm')
    },
    getHours (hour) {
      if (this.tab === 1) return this.$formatter.replaceDotWithComma(hour.amount) + ' t'
      const workHour = hour.amount - hour.break_hr
      return `${this.$formatter.replaceDotWithComma(workHour)} (${this.$formatter.replaceDotWithComma(hour.break_hr)})t`
    },
    deleteHourEntry (id) {
      const payload = { url: `hours/${id}`, module: 'hours-others', isSingle: true }
      this.$store.commit('showDeleteDialog', payload)
    }
  },
  watch: {
    'hourlogSingleDayRecords.timeLine' (timeline) {
      const end = isFinite(timeline.end) ? timeline.end : 0
      this.setStartTime(end)
      // this.sliderOptions = this.getTimeLineValues(timeline)
    },
    prevRoute (val) {
      if (this.prevRoute && this.prevRoute.path) this.backLink = this.prevRoute.path
    }
  },
  components: {
    NormalHours: () => import('./houradd/NormalHours'),
    AbsenceHours: () => import('./houradd/AbsenceHours'),
    Slider: () => import('@/components/Slider')
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('getactiveDateHourRecords')
  }
}
</script>
<style>
.custom_box-shadow {
  box-shadow: 0 0px 9px -1px rgba(0, 0, 0, 0.19) !important;
}
.custom_opacity_reduced {
  opacity: 0.7;
}
</style>
